#projects_container {
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 75px;
    margin-bottom: 75px;
}

@media only screen and (max-width: 700px) {
    #projects_container {
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 75px;
        margin-bottom: 75px;
    }
}

#projects_title {
    font-size: clamp(20px, 4vw, 50px);
}

#projects_list_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-color: blueviolet;*/
}

