#aboutmegeneralcontainer {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 20%;
    margin-bottom: 75px;
}

@media only screen and (max-width: 700px) {
    #aboutmegeneralcontainer {
        margin-left: 5%;
        margin-right: 5%;
    }
}

#aboutme_title {
    font-size: clamp(20px, 4vw, 50px);
}

#aboutmecontainer {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
    align-items: center;
}

#aboutmeinfo {
    color: white;
    text-align: justify;
    text-justify: auto;
    letter-spacing: 0.085rem;
    font-size: clamp(13px, 2vw, 22px);
}

#steven_image {
    margin-left: 10%;
    object-fit: cover;
    border-radius: 100%;
    /*min-width: 150px;*/
    width: 40%;
}

@media only screen and (max-width: 1000px) {
    #aboutmecontainer {
        flex-direction: column;
    }

    #steven_image {
        margin-top: 10%;
        margin-left: 0px;
        width: 65%;
    }
}