body {
    background-color: rgb(10, 14, 28);
}

#cubes_images {
    margin: auto;
    margin-top: 30px;
    width: 100%;
    max-width: 1300px;
    display: flex;
}

#cubes_images {
    /*transform: translateY(40px);*/
    animation: animate 5s;
    animation-iteration-count: infinite;
}
@keyframes animate{
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

#main_title {
    z-index: 50;
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    font-size: clamp(30px, 11vw, 175px);
    color: rgb(255, 255, 255);
    margin-top: 11%;
    text-shadow: 2px 2px 4px #0000003d;
}

#by_stevenmj {
    z-index: 50;
    font-weight: 200;
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    font-size: clamp(20px, 4vw, 50px);
    color: rgb(255, 255, 255);
    margin-top: 25%;
}

#subtitle {
    z-index: 50;
    font-weight: 200;
    width: 100%;
    position: absolute;
    left: 50%;
    translate: -50%;
    text-align: center;
    font-size: clamp(5px, 2.5vw, 30px);
    color: rgb(214, 214, 214);
    margin-top: 31%;
}

