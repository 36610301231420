body {
    background-color: rgb(10, 14, 28);
    margin: 0px;
    padding: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

h1, h2, h3 h4, h5, h6 {
    color: rgb(255, 255, 255);
}

p {
    color: rgb(183, 183, 183);
    /*font-weight: 600;*/
}

a {
    width: min-content;
    height: min-content;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
    font-family: 'Open Sans', sans-serif;

}

/* Blue button */
.blue-button {
    background-color: rgb(64, 64, 255);
    border-radius: 5px;
    border-style: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all .3s cubic-bezier(.05, .03, .35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
    font-size: 15px;
    /*font-weight: 600;*/
  }
  
  .blue-button:hover {
      background-color: rgb(13, 13, 180);
  }
  
  
  /* Medium button */
  .medium-button {
      border-radius: 5px;
      cursor: pointer;
      background-color: rgb(64, 64, 255);
      border: none;
      color: white;
      padding: 13px 28px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      height: min-content;
      transition: all .3s cubic-bezier(.05, .03, .35, 1);
    }
    
    .medium-button:hover {
      background-color: rgb(13, 13, 180);
    }

    .mobilejump {
        display: none;
    }

    @media only screen and (max-width: 700px) {
        .mobilejump {
            display: flex;
        }
    }