#navigationcontainer {
    top: 0px;
    position: fixed;
    width: 100%;
   /*margin-left: 10%;
    margin-right: 10%;*/
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(13px, 1vw, 20px);
    z-index: 100;
    transition: top 0.3s;
    background-color: rgb(46, 49, 60);
    box-shadow: rgba(0, 0, 0, 0.233) 0px 5px 15px;
    padding: 15px;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
}

#navigationlist {
    display: flex;
    list-style-type: none;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
}

.linav {
    border-right:1px solid #bbb;
}

.linav:last-child {
    border-right: none;
}

.linav a {
    display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
    #navigationcontainer {
        display: none;
    }
}


/*.linav a:hover:not(.active) {
    background-color: #111;
}

.active {
    background-color: #04AA6D;
}*/

#socialmediacontainer {
    position: fixed;
    /*background-color: blueviolet;*/
    width: 35px;
    display: flex;
    top: 50%;
    left: 3%;
    flex-direction: column;
    transform: translateY(-50%);
}

#navigationlistmobile {
    padding: 0px;
}

@media only screen and (max-width: 700px) {
    #socialmediacontainer {
        display: none;
    }

    #navigationcontainer {
        display: none;
    }
}



.smhrefl {
    width: 100%;
    margin-bottom: 25px;
}

.smimg {
    width: 100%;
}

#navigationcontainermobile {
    padding-top: 50px;
    transform: translateY(-100%);
    display: none;
    position: fixed;
    background-color: rgb(40, 41, 44);
    width: 100%;
    height: 100%;
    flex-direction: column;
    z-index: 100;
    align-content: space-between;
    align-items: stretch;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s ease-in-out;
}

#mobilemenucontainer {
    display: none;
    z-index: 500;
    background-color: rgb(46, 49, 60);
    position: fixed;
    top: 0px;
    width: 100%;
    height: 65px;
    box-shadow: rgba(0, 0, 0, 0.233) 0px 5px 15px;
    padding: 0px;
    align-items: center;
}

#socialmediacontainermobile {
    width: 40%;
    display: none;
    margin-bottom: 115px;
}

@media only screen and (max-width: 700px) {
    #navigationcontainermobile {
        display: flex;
    }
    #mobilemenucontainer{
        display: flex;
        flex-direction: row-reverse;
    }

    .smhrefl {
        margin: 7%;
    }

    #socialmediacontainermobile {
        display: flex;
    }
}



.amobilelist {
    font-size: clamp(18px, 3.5vw, 30px);
}

.linavmov {
    width: 100%;
    background-color: rgb(40, 41, 44);
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linavmov:last-child {
    border-right: none;
}

.linavmov a {
    display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}




/* GENERAL STYLES */
.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}

.hambcontainer {
    margin: 20px;
    height: fit-content;
    display: flex;
}

.hamburger:hover {
    cursor:pointer;
}

.hamburger {
    width: 30px;
    height: 30px;
    position: relative;
}

.hamburger .bar {
    padding: 0;
    width: 30px;
    height: 4px;
    background-color: rgb(195, 205, 255);
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
}

.bar1 {
    top: 0;
}

.bar2,
.bar3 {
    top: 13.5px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}

/* HAMBURGER 1 */
.checkbox1:checked + label > .hamburger1 > .bar1{
    transform: rotate(45deg);
    transform-origin: 5%;
    width: 41px
}

.checkbox1:checked + label > .hamburger1 > .bar2 {
    transform: translateX(-40px);
    background-color: transparent;
}

.checkbox1:checked + label > .hamburger1 > .bar3 {
    transform: translateX(40px);
    background-color: transparent;
}

.checkbox1:checked + label > .hamburger1 > .bar4 {
    transform-origin: 5%;
    transform: rotate(-45deg);
    width: 41px;
}