#contactmegeneralcontainer {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 20%;
    margin-bottom: 75px;
}

@media only screen and (max-width: 700px) {
    #contactmegeneralcontainer {
        margin-left: 5%;
        margin-right: 5%;
    }
}

#contactme_title {
    font-size: clamp(20px, 4vw, 50px);
}

#formcontainer {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}

.formtext {
    margin: 10px;
    color: aliceblue;
    font-size: clamp(10px, 3vw, 25px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
}

.inputbox {
padding: 12px 20px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: clamp(15px, 1.25vw, 25px);
  width: 70%;
}

#formspree {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#senbutton {
    margin: 3%;
}