/* General */
/*body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
}*/

#projectstitle {
    color: rgb(40, 155, 255);
    /*font-size: 50px;*/
    text-align: center;
}

.projectcontainer {
    margin: 10px;
    border-radius: 0px;
    padding: 20px;
    width: 100%;
    max-width: 900px;
    background-color: rgb(36, 45, 61);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.projecttile {
    font-size: clamp(18px, 4vw, 30px);
}

.projectbasicinfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    letter-spacing: 0.085rem;
    font-size: clamp(13px, 2vw, 18px);
    color: aliceblue;
}

/*.projectgeneralinfo {
}*/

.projectmoreinfo {
    background-color: rgb(10, 14, 28);
    padding: 0px;
    border-radius: 0px;
    overflow: hidden;
    height: 0px;
    transition: all .3s ease-out;
    display: flex;
    flex-direction: column;
    letter-spacing: 0.085rem;
    font-size: clamp(13px, 2vw, 22px);
}

.video_wrapper {
    position: relative;
    padding-bottom: 56.25%;
    margin: 40px;
}

.videoiframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-style: none;
}

.imgprj {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    /*translate: -12%;*/
    width: 100%;
    /*height: 100%;*/
    border-style: none;
}

.projecthref {
    width: auto;
    display: flex;
}

.gotoprojectspage {
    margin: auto;
}

@media only screen and (max-width: 1000px) {
    .projectbasicinfo {
        flex-direction: column;
    }
    .medium-button {
        margin-bottom: 20px;
    }
}

/* Speciphic */


#project1 {
    background-color: rgb(36, 45, 61);
    /*border-style: solid;
    border-radius: 10px;
    border-color: rgb(20, 20, 162);*/
}

/*#project1basicinfo {
}*/

#project1generalinfo {
    color: aliceblue;
}


#project1moreinfo {
    background-color: rgb(10, 14, 28);
}





